<template>
  <div class="flex justify-between items-center mb-4">
    <div class="flex gap-2 items-center">
      <h2 class="text-xl font-medium">
        {{ $t("components.theNotificationContent.title") }}
      </h2>
      <transition
        enter-active-class="transition"
        enter-from-class="opacity-0 -translate-x-1"
        enter-to-class="opacity-100 translate-x-0"
        leave-active-class="transition"
        leave-to-class="opacity-0 translate-x-1"
        leave-from-class="opacity-100 translate-x-0"
      >
        <span
          v-if="showNotificationReadUpdatedMessage"
          class="flex items-center gap-2 px-2 py-1 rounded-md text-green-600 text-sm"
        >
          <IconCheck class="w-5 h-5" />
          <span>
            {{ $t("components.theNotificationContent.updated") }}
          </span>
        </span>
      </transition>
    </div>
    <div class="flex gap-2 items-center">
      <ContextMenuList
        :options="[
          {
            name: translate('components.theNotificationContent.markAllAsRead'),
            icon: IconCheck,
            action: markEverythingAsRead,
          },
        ]"
        closeOnOutsideClick
      >
        <template v-slot="{ isOpen, open, close }">
          <NamiButton
            buttonType="secondary"
            :text="!isOpen"
            pill
            small
            noWaves
            @buttonClick="() => (isOpen ? close() : open())"
            :icon="IconDots"
          />
        </template>
      </ContextMenuList>
      <NamiButton
        :href="`/${locale}/settings/notifications`"
        buttonType="secondary"
        text
        pill
        :icon="IconSettings"
        @buttonClick="$emit('navigate')"
      />
    </div>
  </div>
  <div v-if="pending" v-for="_ in Array(5)">
    <div
      class="pl-4 py-2 grid gap-2 sm:grid-cols-[4rem_1fr] grid-cols-[2rem_1fr]"
    >
      <div class="w-16 h-16 sm:p-2 my-auto">
        <Skeleton class="w-full h-full !rounded-full" />
      </div>
      <div class="flex flex-col gap-2 py-2">
        <Skeleton class="h-4" />
        <Skeleton class="flex-grow" />
      </div>
    </div>
  </div>
  <Error v-else-if="error" :error="error" @retry="refresh" />
  <div v-else-if="notifications && notifications.length > 0">
    <TransitionAutoHeight v-model="selectionEnabled">
      <div class="flex gap-2 mb-2">
        <NamiButton
          buttonType="primary"
          text
          small
          :icon="IconCheck"
          @buttonClick="() => updateReadNotifications(selectedIndexes)"
          :disabled="isPerformingAction"
        >
          {{ $t("components.theNotificationContent.markAsRead") }}
        </NamiButton>
        <NamiButton
          buttonType="secondary"
          text
          small
          :icon="IconX"
          @buttonClick="endSelection"
          :disabled="isPerformingAction"
        >
          Cancel
        </NamiButton>
      </div>
    </TransitionAutoHeight>
    <ul>
      <li
        v-for="(notification, index) in notifications"
        :key="notification.id"
        class="group/parent relative grid grid-cols-[1fr_2rem] gap-2 pr-2"
      >
        <NotificationsNamiAny
          class="!transition-[padding,background-color]"
          :class="selectionEnabled && !notification.isRead ? 'pl-8' : 'pl-2'"
          :notification="notification.parsed"
          :is-unread="false"
          @click="
            () => {
              if (selectionEnabled || notification.isRead) return;
              updateReadNotifications([index]);
              $emit('navigate');
            }
          "
        />
        <div class="flex flex-col justify-center">
          <div class="flex justify-center">
            <ContextMenuList
              v-if="!notification.isRead"
              :options="[
                {
                  name: translate(
                    'components.theNotificationContent.markAsRead',
                  ),
                  icon: IconCheck,
                  action: () => updateReadNotifications([index]),
                },
              ]"
              closeOnOutsideClick
            >
              <template v-slot="{ isOpen, open, close }">
                <NamiButton
                  buttonType="secondary"
                  :text="!isOpen"
                  pill
                  small
                  noWaves
                  @buttonClick="() => (isOpen ? close() : open())"
                  :icon="IconDots"
                />
              </template>
            </ContextMenuList>
          </div>
        </div>
        <div
          v-if="!notification.isRead"
          class="group/marker absolute w-8 left-0 top-0 h-full flex flex-col justify-center cursor-pointer"
          :class="{
            'pointer-events-none': disableSelection,
          }"
          @click="
            () => {
              if (disableSelection) {
                return;
              }

              selectionEnabled
                ? selectedIndexes.includes(index)
                  ? unselectIndex(index)
                  : selectIndex(index)
                : enableSelection(index);
            }
          "
        >
          <div
            class="h-2 w-2 flex items-center justify-center overflow-hidden transition-[height,width,background-color] border"
            :class="{
              'group-hover/parent:h-6 group-hover/parent:w-1 group-hover/parent:group-hover/marker:w-2 group-hover/marker:h-6 group-hover/marker:bg-black dark:group-hover/marker:bg-white':
                !disableSelection && !selectionEnabled,
              'sm:h-6 h-5 sm:w-6 w-5 ml-1 rounded-[2rem] border-black dark:border-white':
                selectionEnabled,
              'bg-nami-comi-blue': !notification.isRead && !selectionEnabled,
              'opacity-0 group-hover/marker:opacity-100':
                !selectionEnabled && notification.isRead,
              'rounded-md border-transparent': !selectionEnabled,
              'bg-black dark:bg-white':
                selectionEnabled && selectedIndexes.includes(index),
            }"
          >
            <IconLoader2
              v-if="isPerformingAction"
              class="text-white dark:text-black w-4 h-4 transition animate-spin"
              :class="{
                'opacity-0': !selectedIndexes.includes(index),
                'opacity-100': selectedIndexes.includes(index),
              }"
            />
            <IconCheck
              v-else
              class="text-white dark:text-black w-4 h-4 transition"
              :class="{
                'opacity-0': !selectedIndexes.includes(index),
                'opacity-100': selectedIndexes.includes(index),
              }"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-else class="h-full flex items-center justify-center">
    <div class="mb-32">
      <AsyncImage
        :src="getAbsoluteAssetLink('nami/stickers/wink.png')"
        class="w-48 h-48 mx-auto"
      />
      <p class="text-center">You don't have any notifications</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  IconDots,
  IconCheck,
  IconX,
  IconLoader2,
  IconSettings,
} from "@tabler/icons-vue";
import { Notifications, type NotificationEntryEntity } from "~/src/api";
import { injectNotificationsContext } from "../app/context/sources/notifications/context";

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;
const translate = nuxtApp.$i18n.global.t;

defineProps<{
  disableSelection?: boolean;
  noHeading?: boolean; //not used for now, can remove later
}>();

const emit = defineEmits<{
  (e: "navigate"): void;
}>();

const route = useRoute();
watch(
  () => route.path,
  () => emit("navigate"),
);

const { isPerformingAction, startAction, endAction } = useAction();
const { parse } = useNamiNotificationParser();
const { onBackgroundNotificationsReceived, readNotificationIds } =
  injectNotificationsContext();

const selectionEnabled = ref(false);
const showNotificationReadUpdatedMessage = ref(false);
const selectedIndexes = reactive<number[]>([]);

const {
  pending,
  data: notifications,
  error,
  refresh,
} = useAsyncData(`notifications-detailed`, async () => {
  const token = await getTokenOrThrow();

  const notifications = await Notifications.find(
    {
      limit: 50,
      order: {
        createdAt: "desc",
      },
    },
    token,
  );

  const parsedNotifications = await parseNotifications(notifications.data);

  return parsedNotifications;
});

onBackgroundNotificationsReceived((messages) => {
  messages.length > 0 && refresh();
});

async function parseNotifications(notifications: NotificationEntryEntity[]) {
  const parsedNotifications = await Promise.all(
    notifications.map(async (notification) => {
      const parsed = await parse(notification);
      if (!parsed) return null;

      return {
        id: notification.id,
        isRead: notification.attributes.isRead,
        parsed: parsed,
      };
    }),
  );

  return parsedNotifications.filter(<T,>(n: T | null): n is T => !!n);
}

function enableSelection(startIndex: number) {
  selectionEnabled.value = true;

  selectedIndexes.push(startIndex);
}

function selectIndex(index: number) {
  selectedIndexes.push(index);
}

function unselectIndex(index: number) {
  const foundIndex = selectedIndexes.findIndex((idx) => index === idx);

  if (foundIndex !== -1) {
    selectedIndexes.splice(foundIndex, 1);
  }
}

async function updateReadNotifications(indexes: number[]) {
  assertDefined(notifications);
  startAction();

  await executeWithNotificationOnError(async () => {
    const token = await getTokenOrThrow();

    const notificationsToMarkAsRead = notifications.value.filter((_, idx) =>
      indexes.includes(idx),
    );

    await Notifications.markRead(
      notificationsToMarkAsRead.map((n) => n.id),
      true,
      token,
    );

    indexes.forEach((index) => {
      notifications.value[index].isRead = true;
    });

    notificationsToMarkAsRead.forEach((n) => {
      readNotificationIds.value.add(n.id);
    });

    showNotificationReadUpdatedMessage.value = true;

    setTimeout(() => {
      showNotificationReadUpdatedMessage.value = false;
    }, 2000);
  }).catch(() => {});

  endSelection();
  endAction();
}

async function markEverythingAsRead() {
  assertDefined(notifications);
  startAction();

  await executeWithNotificationOnError(async () => {
    const token = await getTokenOrThrow();

    const allUnread = await Notifications.find(
      { limit: 0, isRead: false, grouped: true },
      token,
    ).then((x) => x.meta.total);

    await Notifications.markReadBefore(
      new Date().toISOString().split(".")[0],
      token,
    );

    // TODO
    // firebaseStore.totalReadMessages += allUnread;
    notifications.value.forEach((notif) => (notif.isRead = true));

    showNotificationReadUpdatedMessage.value = true;

    setTimeout(() => {
      showNotificationReadUpdatedMessage.value = false;
    }, 2000);
  });

  showNotificationReadUpdatedMessage.value = true;

  setTimeout(() => {
    showNotificationReadUpdatedMessage.value = false;
  }, 2000);

  endSelection();
  endAction();
}

function endSelection() {
  selectionEnabled.value = false;
  selectedIndexes.splice(0, selectedIndexes.length);
}
</script>
